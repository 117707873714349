import React from 'react';
import profileMale from '../../media/employees/profileMale.png';
import profileFemale from '../../media/employees/profileFemale.png';
import profileSvein from '../../media/employees/svein2.jpg';
import profileBente from '../../media/employees/bente.jpg';
import profileTim from '../../media/employees/tim.jpg';
import profileVeronika from '../../media/employees/veronika.jpg';
import profileAnne from '../../media/employees/anne2.jpg';
import profileBirgit from '../../media/employees/birgit.jpg';
import profileRoger from '../../media/employees/rogersæter.jpg';
import profileHeidiAukan from '../../media/employees/heidiaukan.jpg';
import profileIngerUlfsnes from '../../media/employees/ingerulfsnes.jpg';
import profileRobinAukan from '../../media/employees/robinaukan.jpg';
import profileKristinKarstensen from '../../media/employees/kristinkarstensen2.jpg';
import profileBirgithLorentsen from '../../media/employees/birgithlorentsen.jpg';
import profileJanJakobRiise from '../../media/employees/janjakobriise.jpg';
import profileJorunnMålen from '../../media/employees/jorunnmålen.jpg';
import profileJonHenning from '../../media/employees/jonhenning.jpg';
import profileIreneBevre from '../../media/employees/irenebevre.jpg';
import profileAseKristin from '../../media/employees/asekristin.jpg';
import profileSylvia from '../../media/employees/Sylvia.jpg';


// import profileTest1 from '../../media/employees/svein2.jpg';

export default function EmployeesPage() {
    return (
        <div className="employee-page">
            <div className='banner'>
                <div className='banner-text'>
                    <h1>Ansatte</h1>
                </div>
            </div>
            <div className='ansatt-container'>
                    {/* ------------------ Smøla ------------------ */}
                    <div>
                        <h1>Smøla</h1>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileSvein} alt='Svein Ivar Iversen'></img></div>
                        <h2>Svein Ivar Iversen</h2>
                        <p className='workPosition'>DL / Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:svein@okonomitjenesten.no">svein@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004791764538">+47 917 64 538</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileJanJakobRiise} alt='Jan Jakob Riise'></img></div>
                        <h2>Jan Jakob Riise</h2>
                        <p className='workPosition'>Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:jan@okonomitjenesten.no">jan@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004741457438">+47 414 57 438</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileAnne} alt='Anne Vikhals Isaksen'></img></div>
                        <h2>Anne Vikhals Isaksen</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:anne@okonomitjenesten.no">anne@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004791822365">+47 918 22 365</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileBente} alt='Bente Bjørneraas'></img></div>
                        <h2>Bente Bjørneraas</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:bente@okonomitjenesten.no">bente@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004798634099">+47 986 34 099</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileVeronika} alt='Veronika Hermansen Brende'></img></div>
                        <h2>Veronika Hermansen Brende</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:veronika@okonomitjenesten.no">veronika@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004791160936">+47 911 60 936</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileMale} alt='Jan Ivar VIkhals'></img></div>
                        <h2>Jan Ivar Vikhals</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:janivar@okonomitjenesten.no">janivar@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004795464592">+47 954 64 592</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileKristinKarstensen} alt='Kristin Glasø Karstensen'></img></div>
                        <h2>Kristin Glasø Karstensen</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:kristin@okonomitjenesten.no">kristin@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004748107223">+47 481 07 223</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileBirgithLorentsen} alt='Birgith Lorentsen'></img></div>
                        <h2>Birgith Lorentsen</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:birgit.lor@okonomitjenesten.no">birgit.lor@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004741359403">+47 413 59 403</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileTim} alt='Tim Lindgren'></img></div>
                        <h2>Tim Lindgren</h2>
                        <p className='workPosition'>Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:tim@okonomitjenesten.no">tim@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004790546979">+47 905 46 979</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileFemale} alt='Hanne Iren Bjørneraas Hopen'></img></div>
                        <h2>Hanne Iren Bjørneraas Hopen</h2>
                        <p className='workPosition'>Regnskapsmedarbeider</p>
                        <p className='email'><a href="mailto:tim@okonomitjenesten.no">hanne@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004790546979">+47 901 24 179</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileAseKristin} alt='Åse Kristin Gjøstøl Hopen'></img></div>
                        <h2>Åse Kristin Gjøstøl Hopen</h2>
                        <p className='workPosition'>Regnskapsmedarbeider</p>
                        <p className='email'><a href="mailto:tim@okonomitjenesten.no">aase.kristin@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004797573179">+47 975 73 179</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileSylvia} alt='Sylvia Hopen Gjøstøl'></img></div>
                        <h2>Sylvia Hopen Gjøstøl</h2>
                        <p className='workPosition'>Regnskapsmedarbeider</p>
                        <p className='email'><a href="mailto:tim@okonomitjenesten.no">sylvia@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004746824116">+47 468 24 116</a></p>
                    </div>
                    {/* <div className='profileCard'>
                        <div className="img-container"><img src={profileTest1} alt='Svein Ivar Iversen'></img></div>
                        <h2>Svein Ivar Iversen</h2>
                        <p className='workPosition'>DL / Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:svein@okonomitjenesten.no">svein@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004791764538">+47 917 64 538</a></p>
                    </div> */}
                    {/* ------------------ Tustna ------------------ */}
                    <div className='row-header'>
                        <h1>Tustna</h1>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileIreneBevre} alt='Irene Bævre'></img></div>
                        <h2>Irene Bævre</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:ibevre@okonomitjenesten.no">ibevre@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004748246379">+47 482 46 379</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileJonHenning} alt='Jon Henning Width'></img></div>
                        <h2>Jon Henning Width</h2>
                        <p className='workPosition'>Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:Jon.henning@okonomitjenesten.no">Jon.henning@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004797103843">+47 971 03 843</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileJorunnMålen} alt='Jorunn Målen'></img></div>
                        <h2>Jorunn Målen</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:jorunn@okonomitjenesten.no">jorunn@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004791798374">+47 917 98 374</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileBirgit} alt='Birgit Hjelen'></img></div>
                        <h2>Birgit Hjelen</h2>
                        <p className='workPosition'>Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:birgit@okonomitjenesten.no">birgit@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004741664044">+47 416 64 044</a></p>
                    </div>
                    {/* ------------------ Aure ------------------ */}
                    <div className='row-header'>
                        <h1>Aure</h1>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileHeidiAukan} alt='Heidi Aukan'></img></div>
                        <h2>Heidi Aukan</h2>
                        <p className='workPosition'>Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:heidi@okonomitjenesten.no">heidi@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004799553425">+47 995 53 425</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileRobinAukan} alt='Robin Bergseth'></img></div>
                        <h2>Robin Bergseth</h2>
                        <p className='workPosition'>Regnskapsfører</p>
                        <p className='email'><a href="mailto:robin@okonomitjenesten.no">robin@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004740436076">+47 404 36 076</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileIngerUlfsnes} alt='Inger Ulfsnes'></img></div>
                        <h2>Inger Ulfsnes</h2>
                        <p className='workPosition'>Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:Inger.ulfsnes@okonomitjenesten.no">Inger.ulfsnes@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004798063168">+47 980 63 168</a></p>
                    </div>
                    {/* ------------------ Andre ------------------ */}
                    <div className='row-header'>
                        <h1>Andre</h1>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileMale} alt='Frode Flataker'></img></div>
                        <h2>Frode Flataker</h2>
                        <p className='workPosition'>Statsautorisert regnskapsfører</p>
                        <p className='email'><a href="mailto:frode@okonomitjenesten.no">frode@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004792435249">+47 924 35 249</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileMale} alt='Kenneth Sørum'></img></div>
                        <h2>Kenneth Sørum</h2>
                        <p className='workPosition'>IKT/API/Regnskapsfører</p>
                        <p className='email'><a href="mailto:kenneth@okonomitjenesten.no">kenneth@okonomitjenesten.no</a></p>
                        <p className='phone'><a href="tel:004791804019">+47 918 04 019</a></p>
                    </div>
                    <div className='profileCard'>
                        <div className="img-container"><img src={profileRoger} alt='Roger Sæther'></img></div>
                        <h2>Roger Sæter</h2>
                        <p className='workPosition'>IKT/API/Regnskapsfører</p>
                        <p className='email'><a href="mailto:roger@datasenteret.no">roger@datasenteret.no</a></p>
                        <p className='phone'><a href="tel:004790869557">+47 908 69 557</a></p>
                    </div>
            </div>
        </div>
    )
}