import React from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import HomePage from "../pages/public/Home";
import AboutPage from "../pages/public/About";
import EmployeesPage from "../pages/public/Employees";
import logo from '../media/logo/logo.svg';

export default function PageRouting() {
    return (
        <Router>
            <div className='navbar-container'>
                <Navbar expand='lg'>
                    <Navbar.Brand>
                        <NavLink to='/'><img src={logo} alt='Økonomitjenesten AS'></img></NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-nabar-nav" />
                    <Navbar.Collapse id="main-nabar-nav">
                        <Nav className="justify-content-end" style={{ flex: 1}} >
                            <NavLink to='/' exact className='nav-link'>Hjem</NavLink>
                            {/* <NavLink to='/about' exact className='nav-link'>Om oss</NavLink> */}
                            <NavLink to='/employees' exact className='nav-link'>Våre ansatte</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className='global-content-box'>
                <Routes>
                    <Route path='/' exact element={<HomePage />} />
                    {/* <Route path='/about' exact element={<AboutPage />} /> */}
                    <Route path='/employees' exact element={<EmployeesPage />} />
                </Routes>
            </div>
        </Router>
    )
}

