import React from 'react';
import PageRouting from './components/PageRouting';
import PageFooter from './components/PageFooter';

export default function App() {
  return (
    <>
      <PageRouting />
      <PageFooter />
    </>
  )
}