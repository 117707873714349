import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import toolbox from '../media/logo/toolbox.png';

export default function PageFooter() {
    return (
        <footer>
            <Row>
                <Col md={6}>
                    <p>Behov for et dyktig og pålitelig regnskapsfirma med det rette verktøyskrinet for deg?</p>
                    <p>Da er du kommet på rett plass, vi dekker dine behov!</p>
                    <div className="facebook">
                        <a href="https://www.facebook.com/okonomitjenestenas/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-square-facebook"></i></a>
                    </div>
                </Col>
                <Col md={6}>
                    <img src={toolbox} alt="Økonomitjenesten AS" className="toolbox"></img>
                </Col>
            </Row>
            <div class="footer-info">
                <p>© 2023 Økonomitjenesten.no AS </p>
                <p>Org.nr: 997 934 121. </p>
                <p>Alle rettigheter reservert. </p>
                <p>Tech-IT Iversen</p>
            </div>
        </footer>
    )
}